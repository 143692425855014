export const CaresPkg = {
    partner: [
      {
        "orgName": "Liberty City Trust",
        "link": "https://www.lctmiami.org/",
        "name":"Elaine Black",
        "email":"eblack@miamigov.com",
        "phone": "",
        "comments":"",
        "keywords":["Process", "Loan Packaging", "Documentation", "Housing", "Education", "Employment", "Liberty City"]
      },
      {
        "orgName": "HAACOF - Haitian American Chamber of Commerce of Florida",
        "link": "http://www.haccof.com/",
        "name":"Paola Pierre",
        "email":"ppierre@haccof.com",
        "phone": "",
        "comments":"",
        "keywords":["Process", "Loan Packaging", "Documentation", "Housing", "Education", "Employment", "Liberty City"]
      },
      {
        "orgName": "NANA - Neighbors and Neighbors Association",
        "link": "",
        "name":"Robert Jones",
        "email":"rjones@nanafl.org",
        "phone": "(305) 756-0605",
        "comments":"",
        "keywords":["Process", "Documentation", "Small Business", "Financial Assistance"]
      },
        {
        "orgName": "SCORE Miami Dade",
        "link": "https://miamidade.score.org/",
        "name":"",
        "email":"score.bizhelp.miami@scorevolunteer.org",
        "phone": "",
        "comments":"",
        "keywords":["Process", "Documentation", "Small Business", "Financial Assistance"]
      },
      {
        "orgName": "Overtown CRA",
        "link": "",
        "name":"Cornelius Shiver",
        "email":"CShiver@miamigov.com",
        "phone": "",
        "comments":"",
        "keywords":["Process", "Documentation", "Loan Packaging", "Bridge Loan",  "Finance", "Disaster Capital", "Business Growth", "Funding", "Technology"]
      },
      {
        "orgName": "SBDC at FIU - Small Business Development Center at FIU",
        "link": "",
        "name":"",
        "email":"Shelly.bernal@floridasbdc.org",
        "phone": "",
        "comments":"",
        "keywords":["Process", "Documentation", "Loan Packaging", "Bridge Loan",  "Finance", "Disaster Capital", "Business Growth", "Funding", "Technology"]
      },
      {
        "orgName": "Miami Downtown Development Authority",
        "link": "https://www.miamidda.com/business-development-research/small-businesses/",
        "name":"",
        "email":" LoanAssistance@miamidda.com",
        "phone": "",
        "comments":"",
        "keywords":["Application", "Loan Packaging", "Documentation", "Employment", "City of Miami; Downtown Miami"]
      },
      {
        "orgName": "YWCA Greater Miami-Dade, Inc.",
        "link": "https://www.ywca-miami.org/",
        "name":"Kerry-Ann Royes",
        "email":"KARoyes@ywca-miami.org",
        "phone": "",
        "comments":"Services for women",
        "keywords":["Loan Packaging", "Documentation",  "Women", "Education"]
      },
      {
        "orgName": "Goldman Sachs 10,000 Small Businesses",
        "link": "https://www.mdc.edu/10ksb/",
        "name": "Pamela Fuertes",
        "email": "pfuertes@mdc.edu",
        "phone": "",
        "comments":"",
        "keywords": ["Loan Packaging", "PPP", "CARES Act", "Business Plan"]
      },
      {
        "orgName": "US Small Business Administration",
        "link": "https://www.sba.gov/offices/district/fl/miami",
        "name": "",
        "email": "",
        "phone": "",
        "comments":"",
        "keywords": ["EIDL", "Loan Guidelines", "SBA Loans", "7a", "CARES Act"]
      },
      {
        "orgName": "M-DCPS",
        "link": "http://procurement.dadeschools.net/",
        "name": "Jennifer Andreu",
        "email": "jandreu@dadeschools.net",
        "phone": "",
        "comments":"",
        "keywords": ["Loan Packaging", "PPP", "CARES Act", "Procurement" ,"New Business", "Small Business Certification"]
      },
      {
        "orgName": "Miami Bayside Foundation",
        "link": "https://miamibaysidefoundation.org/",
        "name": "Joann Milord",
        "email": "joann@mbf.miami",
        "phone": "",
        "comments":"",
        "keywords": ["Haitian Creole Speaking", "Spanish Speaking", "Micro Loans", "Loan Packaging", "Growth Marketing", "Procurement"]
      },
     
    ],
  }
  
  export default CaresPkg
  
